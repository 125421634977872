import CharacterImg from '../components/CharacterImg'
import Character from '../types/Character'
import { type2img } from '../common'
import Cards from '../components/Cards'
export default function CharacterCashBag({ character, onClose, onLeave }: { character: Character, onClose: () => void, onLeave: () => void}) {
  return (<div className="character-cash-bag">
    <button className="button back" onClick={onClose} />
    <section className="upper">
      <CharacterImg img={type2img(character.type)} height="24vh" width="24vh"/>
      <p className="label">現金 {character.money()}</p>
    </section>
    <button className="button leave" onClick={onLeave} />
    <ol className="card-set">
      <li><Cards character={character} type="normal"/></li>
      <li><Cards character={character} type="advance"/></li>
    </ol>
  </div>)
}