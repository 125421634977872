import { useCallback, useRef, useState } from 'react'
import Character from "../types/Character"
import CardImg from './CardImg'
export default function Cards ({character, type, onChange}: {character: Character, type: 'normal' | 'advance', onChange?: (returns: Card) => void}) {
  const [selected, setSelected] = useState<number>(0)
  const [flipping, setFlipping] = useState<boolean>(false)
  const [protect, setProtect] = useState<boolean>(false)
  const el = useRef<HTMLDivElement>(null)
  const cards = character.getCards(type)

  const flip = useCallback(() => {
    if (protect || cards.length <= 1) return
    setProtect(true)
    function flipPhase1() {
      if (el && el.current) {
        el.current.removeEventListener('transitionend', flipPhase1)
        setFlipping(false)
        const firstCard = el.current.querySelector('.card-img') as HTMLDivElement
          firstCard.classList.add('top')
      }
      window.setTimeout(() => {
        el?.current?.addEventListener('transitionend', flipPhase2)
      }, 100)
    }
    function flipPhase2() {
      el?.current?.removeEventListener('transitionend', flipPhase2)
      setProtect(false)
      if (selected === (cards.length - 1)) {
        setSelected(0)
        onChange?.(cards[0])
      } else {
        setSelected(selected + 1)
        onChange?.(cards[selected + 1])
      }
    }
    el?.current?.addEventListener('transitionend', flipPhase1)
    setFlipping(true)
  }, [cards, onChange, protect, selected])

  return (
    <div className={`cards ${type} ${flipping && 'flipping'}`} ref={el}>
      <button className="next" onClick={flip}/>
      <p className="subject">{type === 'normal' ? '採購卡' : '進階卡'}</p>
      <span className="label" style={{lineHeight:2}}>{cards.length}</span>
      <span className="label">張</span>
      <CardImg card={cards[selected === (cards.length - 1) ? 0 : selected + 1]} advance={type === 'advance'} size="33vw"/>
      <CardImg card={cards[selected]} advance={type === 'advance'} size="33vw"/>
    </div>
  )
}