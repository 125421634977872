import { useState, useCallback, useContext } from 'react'
import { Config } from '../Config'
import GameContext from '../GameContext'
import CharacterImg from '../components/CharacterImg'
import CharacterSet from '../types/CharacterSet'
import Character from '../types/Character'
import { playAudio } from '../common'

function randomIn(range: number, exclude?: number[]): number {
  let n = Math.floor(Math.random() * range)
  if (exclude && exclude.includes(n)) n = randomIn(range, exclude)
  return n
}

export default function CharacterSelection({ onResolve }: { onResolve?: () => void }) {

  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [characterIndex, setCharacterIndex] = useState<number>(0)
  const [npc1Index, setNpc1Index] = useState<number>(0)
  const [npc2Index, setNpc2Index] = useState<number>(0)
  const gameContext = useContext(GameContext)

  const decideCharacter = useCallback( () => {
    if (isChecked) return
    setIsChecked(true)
    let npc1 = randomIn(CharacterSet.length, [characterIndex])
    setNpc1Index(npc1)
    let npc2 = randomIn(CharacterSet.length, [characterIndex, npc1])
    setNpc2Index(npc2)
    window.setTimeout(() => {
      const characters = [
        new Character({ type: CharacterSet[characterIndex].type, isNPC: false }),
        new Character({ type: CharacterSet[npc1].type, isNPC: true }),
        new Character({ type: CharacterSet[npc2].type, isNPC: true }),
      ]
      gameContext.setCharacters(characters)
      onResolve?.()
    }, Config.CharacterSelectedDelay)
  }, [characterIndex, gameContext, isChecked, onResolve])

  function onCharacterClick(index: number) {
    playAudio('select')
    setCharacterIndex(index)
  }

  return (
    <div className={'character-selection' + (isChecked ? ' checked' : '')}>
      <section>
        <h1>選角色</h1>
        <ul className="bg-solid">
          {CharacterSet.map((c, i) => {
            return (
              <li className={"character" + (i === characterIndex ? " selected" : "")} key={ c.name } onClick={() => {isChecked || onCharacterClick(i)}}>
                <div className="name">{ c.name }</div>
                <CharacterImg img={ c.image } height="calc(100% - 16vh)"/>
              </li>
            )
          })}
        </ul>
      </section>
      <section>
        <h2>玩家角色</h2>
        <div className="player">
          <CharacterImg img={CharacterSet[characterIndex].image} height="16vh" width="10vh"/>
        </div>
        <h2>電腦角色</h2>
        <div className="npc1">
          {isChecked && (<CharacterImg img={CharacterSet[npc1Index].image} height="16vh" width="10vh"/>)}
        </div>
        <div className="npc2">
          {isChecked && (<CharacterImg img={CharacterSet[npc2Index].image} height="16vh" width="10vh"/>)}
        </div>
        <button className={"btn-next" + (isChecked ? " disabled" : "")} onClick={decideCharacter}/>
      </section>
    </div>
  )
}