import {useState, useContext} from 'react'
import GameContext from '../GameContext'
import HomeDevice from '../types/HomeDevice'
import CharacterSet from '../types/CharacterSet'
import CharacterImg from '../components/CharacterImg'
import CharacterCashBag from './CharacterCashBag'
import { playAudio } from '../common'
export default function CashBag(props: {onClose: () => void}) {
  const gameContext = useContext(GameContext)
  const [selected, setSelected] = useState<number>(0)
  const [showCharacterCashBag, setShowCharacterCashBag] = useState<boolean>(false)
  const type2img = (type: HomeDevice) => CharacterSet.find(s => s.type === type)?.image || ""
  const names = ['自己', '電腦玩家：省省', '電腦玩家：電電']

  function onCharacterClick(index: number) {
    playAudio('select')
    setSelected(index)
  }
  return showCharacterCashBag
    ? (<CharacterCashBag character={gameContext.characters[selected]} onClose={() => setShowCharacterCashBag(false)} onLeave={props.onClose}/>)
    : (<div className="cash-bag" >
      <button className="button back" onClick={props.onClose}/>
      <h1>選擇查看哪位玩家的錢包？</h1>
      <button className="button ok" onClick={() => setShowCharacterCashBag(true)}/>
      <ol className="characters">
        {gameContext.characters.map((c, i) => (
          <li className={'character-wrapper' + (c.isCurrent() ? ' current' : '') + (selected === i ? ' selected' : '')} key={i} onClick={() => onCharacterClick(i)}>
            <CharacterImg img={type2img(c.type)} height="40vh" width="35vh" key={i} />
            <div className="name">{names[i]}</div>
          </li>
        ))}
      </ol>
    </div>
    )
}