import { useContext } from 'react'
import GameContext from './GameContext'

import Intro from './stages/Intro'
import CharacterSelection from './stages/CharacterSelection'
import GameMain from './stages/GameMain'
import { GameData } from './types/GameData'
import GameStage from './types/GameStage'
import Results from './stages/Results'
import { playAudio } from './common'

export default function Stage(props: {stage: GameStage, onClick: () => void}) {
  const gameData = useContext<GameData>(GameContext)

  function stageFragment(stage: GameStage) {
    switch (stage) {
      case GameStage.Intro:
        return (
          <Intro onResolve={() => {
            gameData.setStage(GameStage.CharacterSelection);
            playAudio('bgm', .5)
          }} />
        )
      case GameStage.CharacterSelection:
        return (
          <CharacterSelection onResolve={() => gameData.setStage(GameStage.Game)}/>
        )
      case GameStage.Game:
        return (
          <GameMain />
        )
      case GameStage.Result:
        return (
          <Results />
        )
    }
  }
  
  return (
    <div className="stage" onClick={() => props.onClick()}>
      {stageFragment(props.stage)}
    </div>
  )
}