import { useCallback, useState } from "react";
import { card2name } from "../common";
import CardImg from "../components/CardImg";
import Cards from "../components/Cards";
import { Config } from "../Config";
import CellType from "../types/CellType";
import Character from "../types/Character";
import ConfirmType from "../types/ConfirmType";

export default function ConfirmScreen({ type, character, card, cell, arrears, onClose }: { type: ConfirmType, character: Character, card?: Card, cell?: MazeCell, arrears?: number, onClose: (v: any) => void }) {
  const [message, setMessage] = useState<string>('')
  const [selectedCard, setSelectedCard] = useState<Card>(character.getCards('advance')[0] || character.getCards('normal')[0])
  const [showConfirm, setShowConfirm] = useState<boolean>(false)

  const showMessage = useCallback((message: string, returns?: any, delay: number = Config.ConfirmDelay) => {
    setMessage(message)
    window.setTimeout(() => { onClose?.(returns) }, delay)
  }, [onClose])

  switch (type) {
    case ConfirmType.Buy:
    case ConfirmType.BuyAdvance:
      return (
        <div className={"confirm-screen buy" + (message ? ' no-bg' : '')}>
          <button className={"button leave" + (message ? ' hide' : '')} onClick={() => onClose?.(false)}/>
          <CardImg card={card} advance={type === ConfirmType.BuyAdvance} classes={(message ? ['hide'] : undefined)}/>
          <div className={"description" + (message ? ' hide' : '')}>
            {type === ConfirmType.Buy
              ? (<h1>是否用{card?.price}元購買{card2name(card)}？</h1>)
              : (<h1>是否用{card?.advancePrice}元升級為節能家電？</h1>)}
            <span className="label">錢包 {character?.money()}</span>
            <button className={"button pay" + (message ? ' hide' : '')} onClick={() => showMessage('購買完成', true)}/>
          </div>
          {message && (<div className="message">{message}</div>)}
        </div>
      )
    case ConfirmType.PayMaintainFare:
      return (
        <div className={"confirm-screen pay" + (message ? ' no-bg' : '')}>
          <div className="description">
            <h1>支付{card?.maintainFare}元保養費</h1>
            <span className="label">錢包 {character?.money()}</span>
            <button className={"button pay" + (message ? ' hide' : '')} onClick={() => showMessage('扣款完成')}/>
          </div>
          {message && (<div className="message">{message}</div>)}
        </div>
      )
    case ConfirmType.PayBank:
      return (
        <div className={"confirm-screen pay" + (message ? ' no-bg' : '')}>
          <div className="description">
            <h1>支付{card?.maintainFare || arrears}元罰款</h1>
            <span className="label">錢包 {character?.money()}</span>
            <button className={"button pay" + (message ? ' hide' : '')} onClick={() => showMessage('扣款完成')}/>
          </div>
          {message && (<div className="message">{message}</div>)}
        </div>
      )
    case ConfirmType.SellCard:
      const advance = character.getCards('advance').length > 0
      const cardLabel = advance ? '進階卡' : '採購卡'
      const subtitle = cell?.type === CellType.Destiny ? ('支付' + arrears + '元罰款') : ('支付' + (cell?.maintainFare || 0) + '元保養費')
      return (
        <div className={"confirm-screen sell" + (showConfirm ? ' no-bg' : '')}>
          <div className={"description" + (showConfirm ? ' hide' : '')}>
            <h1>{subtitle}</h1>
            <Cards character={character} type={advance ? 'advance' : 'normal'} onChange={c => setSelectedCard(c) }/>
            <button className="button pay" onClick={() => setShowConfirm(true)}/>
          </div>
          {message
            ? (<div className="message">{message}</div>)
            : (showConfirm && (<div className="confirm">
            <h2>賣掉這張{cardLabel}支付款項？</h2>
            <CardImg card={selectedCard} size='35vh' advance={advance}/>
            <div className="buttons">
              <button className="button yes" onClick={() => {
                if (character.money() + (advance ? selectedCard.advancePrice : selectedCard.price) < (cell?.maintainFare || arrears || 0)) {
                  showMessage('金錢不足以已支付款項，請再販售其他卡片', selectedCard)
                } else {
                  showMessage('扣款完成', selectedCard)
                }
              }} />
              <button className="button no" onClick={() => setShowConfirm(false)} />
            </div>
          </div>))}
        </div>
      )
    default:
      return <div className="confirm-screen"/>
  }
}