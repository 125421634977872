import HomeDevice from "./HomeDevice"

export default class Character {
  private _money: number = 10000
  private _owns: Set<MazeCell> = new Set()
  private _ownsAdvance: Set<MazeCell> = new Set()
  private _position: number = 0
  private _current: boolean = false
  private _rounds: number = 0
  private _freeze: number = -1
  private _message: string = ''
  
  public readonly type: HomeDevice
  public readonly isNPC: boolean
  public element: HTMLElement
  public redraw: () => void = () => { }
  public limit = 27
  public pass0Money:boolean = false

  public constructor({type, isNPC }: {type: HomeDevice, isNPC: boolean}) {
    this.type = type
    this.isNPC = isNPC
  }

  public nextPosition(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._position++
      if (this._position > this.limit) this._position = 0
      this.redraw()
      const endMove = (e:TransitionEvent) => {
        this.element.removeEventListener('transitionend', endMove)
        resolve('end move')
      }
      this.element.addEventListener('transitionend', endMove)
    })
  }

  public position(): number {
    return this._position
  }

  public setPosition(v:number): void {
    this._position = v
    this.redraw()
  }

  public say(message: string, wait: number = 2000, autoHide: boolean = true): Promise<any> {
    this._message = message
    this.redraw()
    if (wait === 0) return Promise.resolve()
    return new Promise(resolve => {
      window.setTimeout(() => {
        if (autoHide) {
          this._message = ''
          this.redraw()
        }
        resolve(null)
      }, wait)
    })
  }

  public showPass0Money(wait: number = 2000, autoHide: boolean = true): Promise<any> {
    this.pass0Money = true
    this.redraw()
    if (wait === 0) return Promise.resolve()
    return new Promise(resolve => {
      window.setTimeout(() => {
        if (autoHide) {
          this.pass0Money = false
          this.redraw()
        }
        resolve(null)
      }, wait)
    })
  }

  public buy(card: Card, advance: boolean = false):void {
    this.decreaseMoney(advance ? card.advancePrice : card.price)
    card.owner = this
    if (advance) this._ownsAdvance.add(card)
    this._owns.add(card)
  }

  public buyAdvance(card: Card):void {
    this.buy(card, true)
  }

  public sell(card: Card): void {
    if (this._ownsAdvance.has(card)) {
      this._ownsAdvance.delete(card)
      this.increaseMoney(card.advancePrice)
    } else {
      this._owns.delete(card)
      this.increaseMoney(card.price)
      if (!this._owns.has(card)) card.owner = null
    }
  }

  public pay(amount: number): {to: (character: Character) => void} {
    this.decreaseMoney(amount)
    return {
      to: (character: Character) => character.increaseMoney(amount)
    }
  }

  public cardsAmount(): number {
    return this._owns.size + this._ownsAdvance.size
  }

  public message(): string {
    return this._message
  }

  public isCurrent(): boolean {
    return this._current
  }

  public setCurrent(v: boolean): void {
    this._current = v
  }

  public money(): number {
    return this._money
  }

  public setMoney(v: number): void {
    this._money = v
  }

  public getCards(type: 'normal' | 'advance' = 'normal'): Card[] {
    if (type === 'normal') return [...this._owns]
    else return [...this._ownsAdvance]
  }

  public nextRound(): number {
    return ++this._rounds
  }

  public rounds(): number {
    return this._rounds
  }

  public freeze(rounds: number): void {
    this._freeze = rounds
  }

  public checkFreeze(rounds: number): boolean {
    if (this._freeze > 0) {
      this._freeze--
      return true
    }
    if (this._freeze === 0) { this._freeze = -1 }
    return false
  }

  public isFreeze(): boolean {
    return this._freeze !== -1
  }

  public increaseMoney(money: number) {
    this._money += money
  }

  public decreaseMoney(money: number) {
    this._money -= money
  }

  public getWealth(): number {
    let wealth = this.money()
    wealth = Array.from(this._owns).reduce((p, c) => (p += c.price), wealth)
    wealth = Array.from(this._ownsAdvance).reduce((p, c) => (p += (c.advancePrice + c.saveCost)), wealth)
    return wealth
  }
}