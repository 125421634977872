enum HomeDevice {
  AirConditioner,
  Refrigerator,
  LED,
  WaterDispenser,
  WaterPot,
  TV,
  Computer,
  WashingMachine,
  Fan,
  HairDryer,
  RicePot,
  Dryer,
  Microwave,
  Heater,
  WaterHeater,
  Audio,
}

export default HomeDevice