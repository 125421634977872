import { card2img } from "../common"

export default function CardImg ({card, advance = false, size = '30vw', classes = []}: {card?: Card, advance?: boolean, size?: string, classes?: string[]}) {
  const img = card2img(card)
  const matches = size.match(/(\d+)(\w+)/)
  const sizeNumber = matches?.[1] || 0
  const sizeUnit = matches?.[2] || 'px'
  const style = {
    height: size,
    width: +sizeNumber * 0.65 + sizeUnit
  }
  const newClasses = classes.concat('card-img', img, advance ? 'advance' : '').join(' ')
  return (
    <div className={newClasses} style={style} />
  )
}
