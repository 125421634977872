import React, {useState} from 'react';
import Character from './types/Character'
import GameContext from './GameContext'
import {defaultContext} from './GameContext'
import './App.scss';
import Stage from './Stage'
import GameStage from './types/GameStage';
import { playAudio } from './common';

function App() {
  let [stage, setStage] = useState<GameStage>(GameStage.Intro)
  let [characters, setCharacters] = useState<Character[]>([])
  const gameData = Object.assign(defaultContext, {
    stage,
    setStage,
    characters,
    setCharacters,
  })

  const onClick = () => {
    if (stage === GameStage.Result) {
      window.open('https://forms.gle/LoaMijF5A1iTuwjG7')
      setStage(GameStage.Intro)
      playAudio('bgm')
    }
  }

  return (
    <GameContext.Provider value={gameData}>
      <div className="App">
        <Stage stage={stage} onClick={() => onClick()}/>
      </div>
    </GameContext.Provider>
  );
}

export default App;
