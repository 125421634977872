import { useEffect, useRef, useState } from "react"
import { playAudio } from "../common"
import MoneyImg from "../components/MoneyImg"
import { Config } from "../Config"

const [PLUS, MINUS] = [0, 1]
const QuestionSet: {amount: 100 | 500 | 1000 | 3000, method: number}[] = [
  { amount: 100, method: MINUS },
  { amount: 100, method: PLUS },
  { amount: 100, method: PLUS },
  { amount: 500, method: MINUS },
  { amount: 500, method: MINUS },
  { amount: 100, method: MINUS },
  { amount: 100, method: MINUS },
  { amount: 100, method: MINUS },
  { amount: 1000, method: PLUS },
]

export default function Destiny({ questionNO, onClose }: { questionNO: number, onClose: (money: number) => void }) {
  // const [qid, setQid] = useState<number>(questionNO)
  const qid = questionNO
  const card = useRef<HTMLDivElement>(null)
  const [showMoney, setShowMoney] = useState<number>(0)
  
  useEffect(() => {
    setShowMoney(QuestionSet[qid].amount * (QuestionSet[qid].method === PLUS ? 1 : -1))
    if (QuestionSet[qid].method === PLUS) {
      playAudio('good-luck')
      window.setTimeout(() => onClose?.(QuestionSet[qid].amount), Config.DestinyDuringTimeout)
    } else {
      playAudio('bad-luck')
    }
  }, [onClose, qid])
  
  return (<div className="destiny" onKeyDown={ () => {/* setQid((qid + 1) % 9) */} }>
    <div className={"card q" + (qid + 1)} ref={card} />
    {showMoney > 0 && <MoneyImg amount={QuestionSet[qid].amount} size="35vh"/>}
    {showMoney < 0 && <div className="btn-cash" onClick={ () => onClose?.(showMoney)}>繳電費</div>}
  </div>)
}