import { useState, useRef, useEffect, useContext } from 'react'
import GameState from '../types/GameState'
import { type2img } from '../common'
import GameContext from '../GameContext'
import CharacterImg from '../components/CharacterImg'
import CashBag from './CashBag'
import Dice from './Dice'
import Character from '../types/Character'
import ConfirmScreen from './ConfirmScreen'
import ConfirmType from '../types/ConfirmType'
import Opportunity from './Opportunity'
import Destiny from './Destiny'
import Bankrupt from './Bankrupt'
import TimeUp from './TimeUp'
import MoneyImg from '../components/MoneyImg'
import Flag from '../components/Flag'

enum Screen {
  Map,
  CashBag,
  CharacterCashBag
}

export default function MonopolyMap(props: { grid: number[][], columnSizes: number[], rowSizes: number[], state: GameState, meta: any }) {
  const gameContext = useContext(GameContext)
  const [screen, setScreen] = useState<number>(Screen.Map)
  const monopoly = useRef<HTMLDivElement>(null)

  const index2pos = (index: number): { x: string, y: string } => {
    if (index === -1) return {x: 'calc(51% - 5vw)', y: 'calc(32% - 12vh)'}
    for (let y = 0; y < props.grid.length; y++) {
      let x = props.grid[y].findIndex(r => r === index)
      if (x !== -1) {
        let py = 0
        for (let row = 0; row <= y; row++) py += (row === y) ? (props.rowSizes[row] / 2) : props.rowSizes[row]
        let px = 0
        for (let col = 0; col <= x; col++) px += (col === x) ? (props.columnSizes[col] / 2) : props.columnSizes[col]
        return {
          x: `calc(${px * 100}% - 5vw)`,
          y: `calc(${py * 100}% - 12vh)`
        }
      }
    }
    return {x: '-1px', y: '-1px'}
  }

  const isCollision = (character: Character): boolean => {
    return gameContext.characters.some(c => c !== character && c.position() === character.position())
  }

  useEffect(() => {
    if (monopoly.current) {
      const characterEl = monopoly.current.querySelectorAll('.character-img')
      for (let c = 0; c < characterEl.length; c++) {
        gameContext.characters[c].element = characterEl[c] as HTMLElement
      }
    }
  })

  function getFlagsFragment() {
    return (<div className='flags'>
      {gameContext.characters.map(character => {
        const advanceCards = character.getCards('advance')
        const cards = character
          .getCards('normal')
          .filter(card => advanceCards.every(a => a.type !== card.type))
        const typeName = type2img(character.type)
        return advanceCards
          .map(card => (
            <Flag
              img={typeName}
              {...index2pos(gameContext.mazeCells.indexOf(card))}
              height='8vh'
              width='8vh'
              classes={['advance']}
              key={typeName + card.type}
            />
          ))
          .concat(
            cards.map(card => (
              <Flag
                img={typeName}
                {...index2pos(gameContext.mazeCells.indexOf(card))}
                height='8vh'
                width='8vh'
                key={typeName + card.type}
              />
            ))
          )
      })}
    </div>)
  }

  function getCharactersFragment() {
    function getClasses(character: Character): string[] {
      const position = character.position()
      const classes = []
      if (position < 8) classes.push('first-row')
      if (position > 6 && position < 15) classes.push('last-column')
      if (position > 13 && position < 22) classes.push('last-row')
      if (position > 20 || position === 0) classes.push('first-column')
      return classes
    }
    return (
      <div className="characters">
        {gameContext.characters.map((c, i) => (
          <div className={['character-wrapper', c.isCurrent() ? 'current' : '', isCollision(c) ? 'collision' : '', c.isFreeze() ? 'freeze' : ''].concat(getClasses(c)).join(' ')} key={i}>
            <div className={"jumping-wrapper " + type2img(c.type)}>
              <CharacterImg img={type2img(c.type)} {...index2pos(c.isFreeze() ? -1 : c.position())} height="10vh" width="10vh" classes={getClasses(c)} message={c.message()}/>
            </div>
          </div>
        ))}
      </div>
    )
  }

  function showPass0Money():boolean {
    return gameContext.characters.some((c, i) => c.pass0Money)
  }

  function getStateFragment() {
    switch (screen) {
      case Screen.Map:
        switch (props.state) {
          case GameState.PlayerMap:
            return (
              <div className="bg">
                {getFlagsFragment()}
                {getCharactersFragment()}
                <div className="ui">
                  <div className="button roll-dice" onClick={() => gameContext?.resolver?.()}/>
                  <div className="button show-cash-bag" onClick={() => setScreen(Screen.CashBag)}/>
                </div>
              </div>
            )
          case GameState.NPCMap:
            return (
              <div className="bg">
                {getFlagsFragment()}
                {getCharactersFragment()}
                {showPass0Money() && <MoneyImg amount={3000} size="25vh"/>}
              </div>
            )
          case GameState.RollDice:
            const roll = Math.floor(Math.random() * 6) + 1
            return (
              <div className="bg">
                {getFlagsFragment()}
                {getCharactersFragment()}
                <Dice roll={roll} />
              </div>
            )
          case GameState.Buy:
          case GameState.BuyAdvance:
          case GameState.PayMaintainFare:
          case GameState.PayBank:
            const card = gameContext.mazeCells[gameContext.currentCharacter().position() || -1]
            const type = {
              [GameState.Buy]: ConfirmType.Buy,
              [GameState.BuyAdvance]: ConfirmType.BuyAdvance,
              [GameState.PayMaintainFare]: ConfirmType.PayMaintainFare,
              [GameState.PayBank]: ConfirmType.PayBank,
            }
            return <ConfirmScreen character={gameContext.currentCharacter()} type={type[props.state]} card={card} arrears={props.meta} onClose={buy => gameContext?.resolver?.(buy)} />
          case GameState.SellCard:
            const cell = gameContext.mazeCells[gameContext.currentCharacter().position() || -1]
            return <ConfirmScreen character={gameContext.currentCharacter()} type={ConfirmType.SellCard} cell={cell} arrears={props.meta} onClose={card => gameContext?.resolver?.(card)} />
          case GameState.Opportunity:
            const OID = Math.floor(Math.random() * 12)
            return <Opportunity questionNO={OID} onClose={(isCorrect: boolean) => gameContext?.resolver?.(isCorrect)}/>
          case GameState.Destiny:
            const DID = Math.floor(Math.random() * 9)
            return <Destiny questionNO={DID} onClose={money => gameContext?.resolver?.(money)}/>
          case GameState.Bankrupt:
            return <Bankrupt/>
          case GameState.TimeUp:
            return <TimeUp/>
        }
        break
      case Screen.CashBag:
        return (<CashBag onClose={() => setScreen(Screen.Map)}/>)
    }
  }

  return (
    <div className="monopoly-map" ref={monopoly}>
      {getStateFragment()}
      {/* {props.meta?.showBattery && <div className="battery"/>} */}
    </div>
  )
}