import {money2img} from '../common'
export default function MoneyImg({ amount, size, classes = [] }: { amount: 100 | 500 | 1000 | 3000 | 9000, size: string, classes?: string[] }) {
  const img = money2img(amount)
  const matches = size.match(/(\d+)(\w+)/)
  const sizeNumber = matches?.[1] || 0
  const sizeUnit = matches?.[2] || 'px'
  const style = {
    height: size,
    width: +sizeNumber * 1.66 + sizeUnit
  }
  const newClasses = classes.concat(img).join(' ')
  return (
    <div className="money-img">
      <div className={newClasses} style={style} />
    </div>
  )
}
