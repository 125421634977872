import screenfull from 'screenfull'
import { useState } from "react"

export default function Intro({ onResolve }: { onResolve?: () => void }) {
  const [step, setStep] = useState(0)
  const steps = ['introduction', 'rules', '']
  const onClick = () => {
    if (step < steps.length - 1) {
      if (screenfull.isEnabled && !screenfull.isFullscreen) screenfull.request()
      setStep(step + 1)
    } else {
      onResolve?.()
    }
  }
  return (
    <div className={["intro", steps[step]].join(' ')} onClick={() => onClick()}/>
  )
}