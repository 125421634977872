import { useContext, useEffect } from 'react'
import GameContext from '../GameContext'

export default function TimeUp () {
  const gameContext = useContext(GameContext)

  useEffect(() => {
    window.setTimeout(() => gameContext.resolver?.(null, 'TimeUp'), 3000)
  })

  return (
    <div className='time-up'>
      <h1>時間到…</h1>
      <div className="sandglass"></div>
    </div>
  )
}
