import HomeDevice from './HomeDevice'
const CharacterSet: { name: string, image: string, type: HomeDevice }[] = [
  {
    name: 'LED燈具',
    image: 'led',
    type: HomeDevice.LED
  },
  {
    name: '音響',
    image: 'audio',
    type: HomeDevice.Audio
  },
  {
    name: '烘衣機',
    image: 'dryer',
    type: HomeDevice.Dryer
  },
  {
    name: '開飲機',
    image: 'water-dispenser',
    type: HomeDevice.WaterDispenser
  },
  {
    name: '微波爐',
    image: 'microwave',
    type: HomeDevice.Microwave
  },
  {
    name: '電視',
    image: 'tv',
    type: HomeDevice.TV
  },
  {
    name: '熱水瓶',
    image: 'water-pot',
    type: HomeDevice.WaterPot
  },
  {
    name: '電鍋',
    image: 'electric-pot',
    type: HomeDevice.RicePot
  },
]

export { CharacterSet }
export default CharacterSet