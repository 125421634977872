import React from "react"
import GameState from '../types/GameState'
import Character from '../types/Character'
import GameContext from "../GameContext"
import MonopolyMap from './MonopolyMap'
import CellType from "../types/CellType"
import HomeDevice from "../types/HomeDevice"
import GameStage from "../types/GameStage"
import { Config } from "../Config"
import { card2name, playAudio, stopAudio } from "../common"

const GridDesc =
  [[0, 1, 2, 3, 4, 5, 6, 7],
    [27, -1, -1, -1, -1, -1, -1, 8],
    [26, -1, -1, -1, -1, -1, -1, 9],
    [25, -1, -1, -1, -1, -1, -1, 10],
    [24, -1, -1, -1, -1, -1, -1, 11],
    [23, -1, -1, -1, -1, -1, -1, 12],
    [22, -1, -1, -1, -1, -1, -1, 13],
    [21, 20, 19, 18, 17, 16, 15, 14],
  ]

const ColumnDesc = [0.17, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.17]
const RowDesc = [0.179, 0.107, 0.107, 0.107, 0.107, 0.107, 0.107, 0.179]

const Cells: MazeCell[] = makeCellsDefinition([
  {
    type: CellType.Start,
  },
  {
    type: HomeDevice.LED,
    price: 5000,
    maintainFare: 500,
    saveCost: 100 
  },
  {
    type: HomeDevice.AirConditioner,
    price: 20000,
    maintainFare: 2000,
    saveCost: 2000 
  },
  {
    type: CellType.Opportunity
  },
  {
    type: HomeDevice.Refrigerator,
    price: 15000,
    maintainFare: 1500,
    saveCost: 100 
  },
  {
    type: CellType.Destiny
  },
  {
    type: HomeDevice.WaterDispenser,
    price: 5000,
    maintainFare: 500,
    saveCost: 700 
  },
  {
    type: CellType.Forward_1
  },
  {
    type: HomeDevice.WaterPot,
    price: 4000,
    maintainFare: 400,
    saveCost: 300 
  },
  {
    type: HomeDevice.TV,
    price: 10000,
    maintainFare: 1000,
    saveCost: 100 
  },
  {
    type: CellType.Opportunity
  },
  {
    type: HomeDevice.Computer,
    price: 10000,
    maintainFare: 1000,
    saveCost: 100 
  },
  {
    type: HomeDevice.WashingMachine,
    price: 12000,
    maintainFare: 1200,
    saveCost: 200 
  },
  {
    type: CellType.Destiny
  },
  {
    type: CellType.ReDice
  },
  {
    type: HomeDevice.HairDryer,
    price: 3000,
    maintainFare: 300,
    saveCost: 100 
  },
  {
    type: HomeDevice.RicePot,
    price: 3000,
    maintainFare: 300,
    saveCost: 100 
  },
  {
    type: CellType.Opportunity
  },
    {
    type: HomeDevice.Dryer,
    price: 15000,
    maintainFare: 1500,
    saveCost: 100 
  },
  {
    type: HomeDevice.Microwave,
    price: 3000,
    maintainFare: 300,
    saveCost: 100 
  },
  {
    type: CellType.Destiny
  },
  {
    type: CellType.Forward_3
  },
  {
    type: HomeDevice.Heater,
    price: 10000,
    maintainFare: 1000,
    saveCost: 100 
  },
  {
    type: HomeDevice.WaterHeater,
    price: 16000,
    maintainFare: 1600,
    saveCost: 100 
  },
  {
    type: CellType.Opportunity
  },
  {
    type: HomeDevice.Audio,
    price: 12000,
    maintainFare: 1200,
    saveCost: 100 
  },
  {
    type: HomeDevice.Fan,
    price: 5000,
    maintainFare: 500,
    saveCost: 200 
  },
  {
    type: CellType.Destiny
  },
])

function makeCellsDefinition (def: {type: number, price?: number, maintainFare?: number, saveCost?: number}[]):MazeCell[] {
  return def.map(d => {
    return Object.assign({
      owner: null,
      advance: false,
      price: 0,
      advancePrice: d.price ? (d.price / 2) : 0,
      maintainFare: 0,
      saveCost: 0,
      type: d.type
    }, d)
  })
}

function getMazeCell (character: Character):MazeCell {
  return Cells[character.position()]
}

function getSimDestinyAnswer (): number {
  const QuestionSet: number[] = [
    -100,
    100,
    100,
    -500,
    -500,
    -100,
    -100,
    -100,
    1000,
  ]
  return QuestionSet[Math.floor(Math.random() * QuestionSet.length)]
}

function getSimOpportunityAnswer (): number{
  const correct: boolean = (Math.random() * 10) > 3
  if (correct) return 500
  return 0
}

class GameMain extends React.Component<any, {state: GameState, ref: any}>{
  static contextType = GameContext
  private _timeoutID: number
  private _isTimeUp: boolean

  constructor(props:any) {
    super(props)
    this.state = {
      state: GameState.PlayerMap,
      ref: null
    }
    this._isTimeUp = false
  }

  componentDidMount() {
    this.context.characters.forEach((character: Character) => character.redraw = this.forceUpdate.bind(this))
    this.context.setMazeCells(Cells)

    this
      .run()
      .catch(() => {
        playAudio('timeup')
        window.setTimeout(() => {
          if (document.querySelector('.monopoly-map') !== null) {
            playAudio('result')
            stopAudio('bgm')
            this.gameOver()
          }
        }, 5000)
        window.setTimeout(() => {
          if (document.querySelector('.time-up') === null) {
            this.goState(GameState.TimeUp, null, 'TimeUp')
          }
        }, 1000)
        return this.goState(GameState.TimeUp, null, 'TimeUp')
      })
      .then(() => {
        playAudio('result')
        stopAudio('bgm')
        this.gameOver()
      })
  }

  public async run(): Promise<void> {
    const pause = (time: number) => new Promise(r => window.setTimeout(r, time))
    
    // wait context is ready
    await new Promise(r => {
      const tid = window.setInterval(() => {
        if (this.context.characters) {
          window.clearInterval(tid)
          r(null)
        }
      })
    })

    let isEnd = false
    this._isTimeUp = false

    return new Promise(async (resolve, reject) => {
      const { characters, stage } = this.context
      this._timeoutID = window.setTimeout(() => {
        clearTimeout(this._timeoutID)
        this._isTimeUp = true
        reject()
      }, Config.GameTimeout)
      while (stage === GameStage.Game && !isEnd && !this._isTimeUp) {
        for (let c = 0; (stage === GameStage.Game) && (c < characters.length) && !isEnd && !this._isTimeUp; c++) {
          const character = characters[c]
          character.setCurrent(true)
          if (character.isNPC) {
            if (character.checkFreeze()) {
              this.goState(GameState.NPCMap, { showBattery: true })
              playAudio('say')
              playAudio('training')
              await character.say('節電訓練班特訓中，暫停一次', 2600)
              stopAudio('training')
              this.goState(GameState.NPCMap, {showBattery: false})
              character.setCurrent(false)
              continue
            }
            let diced: boolean = false
            while (!diced && !isEnd && !this._isTimeUp) {
              playAudio('dice')
              const roll: number = await this.goState(GameState.RollDice)
              diced = true
              await pause(1000)
              this.goState(GameState.NPCMap)
              for (let stepsLeft = roll; stepsLeft > 0 && !isEnd && !this._isTimeUp; stepsLeft--) {
                await character.nextPosition()
                if (stepsLeft === 1) {
                  const cell = getMazeCell(character)
                  switch (cell.type) {
                    case CellType.Forward_1:
                      await pause(1000)
                      stepsLeft += 1
                      break
                    case CellType.Forward_3:
                      await pause(1000)
                      stepsLeft += 3
                      break
                    case CellType.ReDice:
                      diced = false
                      break
                    case CellType.Opportunity:
                      const isCorrect = getSimOpportunityAnswer()
                      if (isCorrect > 0) {
                        character.increaseMoney(500)
                        playAudio('op-correct')
                        playAudio('say')
                        await character.say('機會卡獲得500元')
                      } else {
                        character.freeze(1)
                        this.goState(GameState.NPCMap, { showBattery: true })
                        playAudio('say')
                        playAudio('op-incorrect')
                        playAudio('training')
                        await character.say('機會卡答錯，進節電訓練班特訓', 2600)
                        stopAudio('training')
                        this.goState(GameState.NPCMap, {showBattery: false})
                      }
                      break
                    case CellType.Destiny:
                      const money = getSimDestinyAnswer()
                      if (money < 0) {
                        playAudio('bad-luck')
                        const arrears = Math.abs(money)
                        if (character.money() >= arrears) {
                          playAudio('say')
                          await character.say('命運卡損失' + arrears + '元')
                          character.pay(arrears)
                        } else {
                          if (character.cardsAmount()) {
                            playAudio('say')
                            await character.say('金錢不足，賣卡片換錢')
                            while (character.money() < arrears && character.cardsAmount()) {
                              const card = character.getCards()[0]
                              character.sell(card)
                              this.goState(GameState.NPCMap)
                            }
                          }
                          if (character.money() >= arrears) {
                            playAudio('say')
                            await character.say('命運卡損失' + arrears + '元')
                            character.pay(arrears)
                          } else {
                            playAudio('bankrupt')
                            await this.goState(GameState.Bankrupt)
                            isEnd = true
                            resolve()
                            break
                          }
                        }
                      } else {
                        playAudio('good-luck')
                        playAudio('say')
                        await character.say('命運卡獲得' + money + '元')
                        character.increaseMoney(money)
                      }
                      break
                    case CellType.Start:
                      break
                    default:
                      if (cell.owner === null) {
                        if (character.rounds() !== 0) {
                          if (character.type === cell.type) {
                            if (character.money() >= cell.advancePrice) {
                              character.buyAdvance(cell)
                              playAudio('say')
                              await character.say('同類型獎勵，購買了進階卡')
                            } else {
                              playAudio('say')
                              await character.say('同類型獎勵，但錢不夠買進階卡...')
                            }
                          }
                          else if (character.money() >= cell.price) {
                            character.buy(cell)
                            playAudio('say')
                            await character.say('購買了' + card2name(cell) + '採購卡')
                          } else {
                            playAudio('say')
                            await character.say('錢不夠買採購卡...')
                          }
                        }
                      } else if (cell.owner === character) {
                        if (character.getCards('advance').includes(cell)) {
                          // do nothing
                        } else if (character.money() >= cell.advancePrice) {
                          character.buyAdvance(cell)
                          playAudio('say')
                          await character.say('購買了' + card2name(cell) + '進階卡')
                        } else {
                          playAudio('say')
                          await character.say('錢不夠買進階卡...')
                        }
                      } else {
                        if (character.type === cell.type) {
                          playAudio('say')
                          await character.say('同類型獎勵，免付保養費')
                        } else if (character.money() >= cell.maintainFare) {
                          character.pay(cell.maintainFare).to(cell.owner)
                          playAudio('say')
                          await character.say('付了' + cell.maintainFare + '元保養費')
                          playAudio('say')
                          character.setCurrent(false)
                          cell.owner.setCurrent(true)
                          await cell.owner.say('收到了' + cell.maintainFare + '元保養費')
                          character.setCurrent(true)
                          cell.owner.setCurrent(false)
                        } else {
                          let sell = [0, 0]
                          if (character.cardsAmount()) {
                            playAudio('say')
                            await character.say('金錢不足，賣卡片換錢')
                            while (character.money() < cell.maintainFare && character.cardsAmount()) {
                              const cards = character.getCards()
                              const advanceCards = character.getCards('advance')
                              if (advanceCards.length) {
                                character.sell(advanceCards[advanceCards.length - 1])
                                sell[1]++
                              } else if (cards.length) {
                                character.sell(cards[cards.length - 1])
                                sell[0]++
                              }
                              this.goState(GameState.NPCMap)
                            }
                          }
                          if (character.money() >= cell.maintainFare) {
                            const msg = [
                              (sell[0] > 0) ? ('賣了' + sell[0] + '張採購卡') : '',
                              (sell[1] > 0) ? ('賣了' + sell[1] + '張進階卡') : ''
                            ].filter(v => v)
                            playAudio('say')
                            await character.say(msg.join('、') + '，支付' + cell.maintainFare + '元保養費')
                            character.pay(cell.maintainFare).to(cell.owner)
                            playAudio('say')
                            character.setCurrent(false)
                            cell.owner.setCurrent(true)
                            await cell.owner.say('收到了' + cell.maintainFare + '元保養費')
                            character.setCurrent(true)
                            cell.owner.setCurrent(false)
                          } else {
                            playAudio('bankrupt')
                            await this.goState(GameState.Bankrupt)
                            isEnd = true
                            resolve()
                            break
                          }
                        }
                      }
                  }
                }
                if (character.position() === 0) {
                  character.nextRound()
                  if (character.rounds() === 3) {
                    this._isTimeUp = true
                  } else {
                    character.showPass0Money()
                    playAudio('earn-money')
                    character.increaseMoney(9000)
                  }
                }
              }
              this.goState(GameState.NPCMap)
              await pause(1000)
            }
          } else {
            if (character.checkFreeze()) {
              playAudio('say')
              playAudio('training')
              await character.say('節電訓練班特訓中，暫停一次')
              stopAudio('training')
              character.setCurrent(false)
              continue
            }
            let diced: boolean = false
            while (!diced && !isEnd && !this._isTimeUp) {
              await this.goState(GameState.PlayerMap)
              playAudio('dice')
              const roll: number = await this.goState(GameState.RollDice)
              diced = true
              await pause(1000)
              this.goState(GameState.NPCMap)
              for (let stepsLeft = roll; stepsLeft > 0 && !isEnd && !this._isTimeUp; stepsLeft--) {
                await character.nextPosition()
                if (stepsLeft === 1) {
                  const cell = getMazeCell(character)
                  switch (cell.type) {
                    case CellType.Forward_1:
                      await pause(1000)
                      stepsLeft += 1
                      break
                    case CellType.Forward_3:
                      await pause(1000)
                      stepsLeft += 3
                      break
                    case CellType.ReDice:
                      diced = false
                      break
                    case CellType.Opportunity:
                      const isCorrect = await this.goState(GameState.Opportunity)
                      if (isCorrect) {
                        character.increaseMoney(500)
                      } else {
                        character.freeze(1)
                        this.goState(GameState.NPCMap)
                        playAudio('say')
                        playAudio('training')
                        await character.say('節電訓練班特訓中，下回合暫停一次')
                        stopAudio('training')
                      }
                      break
                    case CellType.Destiny:
                      const money = await this.goState(GameState.Destiny)
                      if (money < 0) {
                        const arrears = Math.abs(money)
                        if (character.money() >= arrears) {
                          await this.goState(GameState.PayBank, arrears)
                          character.pay(arrears)
                        } else {
                          if (character.cardsAmount()) {
                            this.goState(GameState.NPCMap)
                            playAudio('say')
                            await character.say('金錢不足，賣卡片換錢')
                            while (character.money() < arrears && character.cardsAmount()) {
                              const card = await this.goState(GameState.SellCard, arrears)
                              character.sell(card)
                              this.goState(GameState.NPCMap)
                            }
                          }
                          if (character.money() >= arrears) {
                            character.pay(arrears)
                          } else {
                            playAudio('bankrupt')
                            await this.goState(GameState.Bankrupt)
                            isEnd = true
                            resolve()
                            break
                          }
                        }
                      } else {
                        character.increaseMoney(money)
                      }
                      break
                    case CellType.Start:
                      break
                    default:
                      if (cell.owner === null) {
                        if (character.rounds() === 0) {
                          playAudio('say')
                          await character.say('第一圈還不能買喔')
                        } else {
                          if (character.type === cell.type) {
                            if (character.getCards('advance').includes(cell)) {
                              // do nothing
                            } else if (character.money() >= cell.advancePrice) {
                              playAudio('say')
                              await character.say('同類型獎勵，直接購買進階卡')
                              const bought = await this.goState(GameState.BuyAdvance)
                              if (bought) {
                                character.buyAdvance(cell)
                              }
                            } else {
                              playAudio('say')
                              await character.say('金錢不足，無法購買進階卡')
                            }
                          }
                          else if (character.money() >= cell.price) {
                            const bought = await this.goState(GameState.Buy)
                            if (bought) character.buy(cell)
                          } else {
                            playAudio('say')
                            await character.say('金錢不足，無法購買採購卡')
                          }
                        }
                      } else if (cell.owner === character) {
                        if (character.money() >= cell.advancePrice) {
                          const bought = await this.goState(GameState.BuyAdvance)
                          if (bought) character.buyAdvance(cell)
                        } else {
                          playAudio('say')
                          await character.say('金錢不足，無法購買進階卡')
                        }
                      } else {
                        if (character.type === cell.type) {
                          playAudio('say')
                          await character.say('同類型獎勵，免付保養費')
                        } else if (character.money() >= cell.maintainFare) {
                          await this.goState(GameState.PayMaintainFare)
                          character.pay(cell.maintainFare).to(cell.owner)
                          this.goState(GameState.NPCMap)
                          playAudio('say')
                          character.setCurrent(false)
                          cell.owner.setCurrent(true)
                          await cell.owner.say('收到了' + cell.maintainFare + '元保養費')
                          character.setCurrent(true)
                          cell.owner.setCurrent(false)
                        } else {
                          if (character.cardsAmount()) {
                            playAudio('say')
                            await character.say('金錢不足，賣卡片換錢')
                            while (character.money() < cell.maintainFare && character.cardsAmount()) {
                              const card = await this.goState(GameState.SellCard)
                              character.sell(card)
                              this.goState(GameState.NPCMap)
                            }
                          }
                          if (character.money() >= cell.maintainFare) {
                            character.pay(cell.maintainFare).to(cell.owner)
                            playAudio('say')
                            character.setCurrent(false)
                            cell.owner.setCurrent(true)
                            await cell.owner.say('收到了' + cell.maintainFare + '元保養費')
                            character.setCurrent(true)
                            cell.owner.setCurrent(false)
                          } else {
                            playAudio('bankrupt')
                            await this.goState(GameState.Bankrupt)
                            isEnd = true
                            resolve()
                            break
                          }
                        }
                      }
                  }
                }
                if (character.position() === 0) {
                  character.nextRound()
                  if (character.rounds() === 3) {
                    this._isTimeUp = true
                  } else {
                    character.showPass0Money()
                    playAudio('earn-money')
                    character.increaseMoney(9000)
                  }
                }
              }
              this.goState(GameState.NPCMap)
              await pause(1000)
            }
          }
          character.setCurrent(false)
        }
      }
      if (this._isTimeUp) reject()
      else resolve()
    })
  }

  public goState(state: GameState, ref: any = null, name: string = 'default'): Promise<any | null> {
    return new Promise((resolve, reject) => {
      this.context.setResolver(resolve, name)
      if (!this._isTimeUp || state === GameState.TimeUp) this.setState({ state, ref })
    })
  }

  public gameOver() {
    this.context.setStage(GameStage.Result)
  }

  render() {
    return (<main className="game-main">
      <MonopolyMap grid={GridDesc} columnSizes={ColumnDesc} rowSizes={RowDesc} state={this.state.state} meta={this.state.ref}/>
    </main>
    )
  }
}

export default GameMain