import { useState, useRef, MouseEvent } from "react"
import { playAudio } from "../common"
import MoneyImg from "../components/MoneyImg"
import { Config } from "../Config"

const QuestionSet = [
  {
    answer: [1],
    answers: ['5', '10']
  },
  {
    answer: [1],
    answers: ['春季', '夏季', '秋季', '冬季']
  },
  {
    answer: [0],
    answers: ['是', '否']
  },
  {
    answer: [1],
    answers: ['電器開關', '插頭']
  },
  {
    answer: [1, 2],
    answers: ['加鐵窗', '綠化', '隔熱']
  },
  {
    answer: [1],
    answers: ['6', '8', '9']
  },
  {
    answer: [2],
    answers: ['節能', '白熾', 'LED']
  },
  {
    answer: [0, 3],
    answers: ['1', '5', '10', '20', '40']
  },
  {
    answer: [0],
    answers: ['1000', '800']
  },
  {
    answer: [0],
    answers: ['1', '2', '3', '4', '5']
  },
  {
    answer: [2],
    answers: ['20~22', '23~25', '26~28']
  },
  {
    answer: [0],
    answers: ['定時器', '節拍器']
  },
]
export default function Opportunity({ questionNO, onClose }: { questionNO: number, onClose?: (isCorrect: boolean) => void }) {
  // const [qid, setQid] = useState<number>(questionNO)
  const qid = questionNO
  const [showResult, setShowResult] = useState<boolean>(false)
  const [showMoney, setShowMoney] = useState<boolean>(false)
  const [showCrow, setShowCrow] = useState<boolean>(false)
  const Q = QuestionSet[qid]
  const card = useRef<HTMLDivElement>(null)

  function onAnswerMouseDown(e: MouseEvent) {
    const target = e.currentTarget as HTMLDivElement
    const float = target.cloneNode(true) as HTMLDivElement
    const cardEl = card.current
    target.classList.add('drag')
    float.classList.add('dragging')
    if (card.current) card.current.appendChild(float)
    if (cardEl && float) {
      const {width, height} = float.getBoundingClientRect()
      const {x, y} = cardEl.getBoundingClientRect()
      float.style.left = e.nativeEvent.clientX - x - (width / 2) + 'px'
      float.style.top =  e.nativeEvent.clientY - y - (height / 2) + 'px'
    }
  }

  function onAnswerMouseUp(e: MouseEvent) {
    const el = e.currentTarget.querySelector('.drag') as HTMLDivElement
    const target = e.target as HTMLDivElement
    el?.classList.remove('drag')
    if (card.current) {
      const float = card.current.querySelector('.dragging') as HTMLDivElement
      if (float) {
        if (target.classList.contains('question')) {
          if (float.getAttribute('data-pair-answer') === target.getAttribute('data-pair-answer')) {
            target.classList.add('show', 'answered')
          } else {
            target.innerText = float.innerText
            target.classList.add('answered')
          }
          if (target.parentElement?.querySelectorAll('.answered').length === Q.answer.length) {
            if (target.parentElement?.querySelectorAll('.show').length === Q.answer.length) {
              card.current.querySelector('.answers')?.classList.add('hide')
              setShowMoney(true)
              playAudio('op-correct')
              window.setTimeout(() => {
                onClose?.(true)
              }, Config.OpportunityBeforeResultTimeout)
            } else {
              card.current.querySelector('.answers')?.classList.add('hide')
              setShowCrow(true)
              playAudio('op-incorrect')
              window.setTimeout(() => {
                setShowResult(true)
                playAudio('training')
                window.setTimeout(() => onClose?.(false), Config.OpportunityResultDuringTimeout)
              }, Config.OpportunityBeforeResultTimeout)
            }
          }
        }
        float.remove()
      }
    }
  }

  function onAnswerMouseMove(e: MouseEvent) {
    const cardEl = card.current
    const float = e.currentTarget.querySelector('.dragging') as HTMLDivElement
    if (cardEl && float) {
      const {width, height} = float.getBoundingClientRect()
      const {x, y} = cardEl.getBoundingClientRect()
      float.style.left = e.nativeEvent.clientX - x - (width / 2) + 'px'
      float.style.top =  e.nativeEvent.clientY - y - (height / 2) + 'px'
    }
  }
  return (<div className="opportunity" onMouseMove={ e=> onAnswerMouseMove(e)} onMouseUp={e => onAnswerMouseUp(e)}>
    {showResult
      ? (
        <div className="result">
          <div className="battery"></div>
          <div className="description">
            <h1>節電訓練班</h1>
            <h2>*請把省電小撇步好好記下來噢*</h2>
            <div className={"card q" + (qid + 1)}/>
          </div>
        </div>
      )
      : (
        <div className={"card q" + (qid + 1)} ref={card} >
          {Q.answer.map((q, i) => <div className="question" key={'q' + i} data-pair-answer={q} />)}
          <div className="notice">(請從下方選擇正確答案拖曳到空格內)</div>
          <div className="answers">
            {Q.answers.map((a, i) => <div className="answer" key={'a' + i} data-pair-answer={i} onMouseDown={e => {onAnswerMouseDown(e)}}>{a}</div>)}
          </div>
          {showCrow && <div className="crow"/>}
        </div>
    )}
    {showMoney && <MoneyImg amount={500} size="35vh"/>}
  </div>)
}