import HomeDevice from './types/HomeDevice'
import CharacterSet from './types/CharacterSet'
import CardSet from './types/CardSet'

export function type2img (type: HomeDevice):string {
  return CharacterSet.find(s => s.type === type)?.image || ''
}

export function card2img(card?: MazeCell): string {
  if (!card) return 'none'
  else return CardSet.find(c => c.type === card.type)?.image || ''
}

export function card2name(card?: Card): string {
  if (card) {
    return CardSet.find(c => c.type === card.type)?.name || ''
  }
  return ''
}

export function money2img(amount: 100|500|1000|3000|9000): string {
  const moneyImgs = {
    100: 'dolors100',
    500: 'dolors500',
    1000: 'dolors1000',
    3000: 'dolors3000',
    9000: 'dolors9000'
  }
  return moneyImgs[amount]
}

export function playAudio(id: string, volume?: number) {
  const el = document.getElementById(id) as HTMLMediaElement
  el?.play()
  if (volume !== undefined) el.volume = volume
}

export function stopAudio(id: string) {
  const el = document.getElementById(id) as HTMLMediaElement
  if (el) {
    el.currentTime = 0
    el.pause()
  }
}