import React from 'react';
import { GameData } from './types/GameData'
import Character from './types/Character'

const defaultContext: GameData = {
  _resolver: {},
  resolver: (v?: any, name: string = 'default') => {
    if (defaultContext._resolver[name]) {
      defaultContext._resolver[name](v)
      delete defaultContext._resolver[name]
    }
  },
  setResolver: (v: (v: any) => void, name: string = 'default') => {
    defaultContext._resolver[name] = v
  },
  stage: 0,
  setStage: (stage: number) => { },
  characters: [] as Character[],
  setCharacters: (characters: Character[]) => { },
  currentCharacter: () => {
    const current = defaultContext.characters.find(c => c.isCurrent())
    if (!current) { throw Error("No current character") }
    return current
  },
  mazeCells: [] as MazeCell[],
  setMazeCells: (cells: MazeCell[]) => {defaultContext.mazeCells = cells}
}

export {defaultContext}
export default React.createContext<GameData>(defaultContext)