import { useEffect, useState, useCallback, useRef, useContext } from 'react'
import GameContext from '../GameContext'
export default function Dice(props: { roll: number }) {
  const [roll, setRoll] = useState<number>(0)
  const dice = useRef<HTMLDivElement>(null)
  const gameContext = useContext(GameContext)
  const delayRolling = useCallback(function delayRolling(delay: number) {
    setTimeout(() => {setRoll(props.roll)}, delay)
  }, [setRoll, props.roll])

  useEffect(() => {
    if (dice.current) {
      if (dice.current.classList.contains('run')) return
      else dice.current.classList.add('run')
    }
    delayRolling(100)
    let g = 0
    let posY = -180
    ;(function gDrop () {
      setTimeout(() => {
        posY += g
        g += 1
        if (posY > 0) {
          posY = 0
          g *= -0.5
        }
        if (dice?.current) dice.current.style.top = posY + '%'
        if (posY < -3 || Math.abs(g) >= 1) {
          gDrop()
        } else {
          gameContext.resolver?.(props.roll)
        }
      }, 15)
    })()
  })

  return (
    <div className='dice-wrapper' ref={dice}>
      <ol className='dice' data-roll={roll}>
        <li>
          <p/>
        </li>
        <li>
          <p/>
          <p/>
        </li>
        <li>
          <p/>
          <p/>
          <p/>
        </li>
        <li>
          <p/>
          <p/>
          <p/>
          <p/>
        </li>
        <li>
          <p/>
          <p/>
          <p/>
          <p/>
          <p/>
        </li>
        <li>
          <p/>
          <p/>
          <p/>
          <p/>
          <p/>
          <p/>
        </li>
      </ol>
    </div>
  )
}
