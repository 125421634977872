import { Config } from "../Config"
import { useContext, useEffect } from "react"
import GameContext from "../GameContext"

export default function Bankrupt() {
  const gameContext = useContext(GameContext)

  useEffect(() => {
    window.setTimeout(() => {
      gameContext.resolver?.()
    }, Config.BankruptDuringTimeout)
  })

  return (<div className="bankrupt">
    <div className="bankrupt-wrapper"/>
  </div>)
}