import {useContext, useEffect, useRef} from 'react'
import { type2img } from '../common'
import Bill from '../components/Bill'
import CharacterImg from '../components/CharacterImg'
import GameContext from '../GameContext'
export default function Results() {
  const gameContext = useContext(GameContext)
  const bills = useRef<HTMLUListElement>(null)
  useEffect(() => {
    window.setTimeout(() => {
      if (bills.current) {
        bills.current.classList.remove('big')
      }
    }, 100)
    window.setTimeout(() => {
      if (bills.current) {
        bills.current.querySelector('.winner+.character-img')?.classList.add('jumping')
      }
    }, 10000)
  })
  const winner: number = gameContext.characters.reduce((p, c, i) => {
    const v = c.getWealth()
    if (v > p.max) {
      p.max = v
      p.id = i
    }
    return p
  }, { max: 0, id: 0 }).id
  return (<div className="results">
    <ul className="bills big" ref={bills}>
      {gameContext.characters.map((character, index) => {
        return (<li key={character.type} className="bill-wrapper">
          <Bill character={character} classes={index === winner ? ['winner'] : []} />
          <CharacterImg img={type2img(character.type)} height='18vh' width='18vh' />
          <div className="money">{character.getWealth()}</div>
      </li>)
      })}
      <h1>節電總金額PK</h1>
      <div className="notice">點擊畫面任意處繼續...</div>
    </ul>
  </div>)
}