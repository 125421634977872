enum GameState {
  PlayerMap,
  NPCMap,
  RollDice,
  CashBag,
  Buy,
  BuyAdvance,
  PayMaintainFare,
  SellCard,
  Opportunity,
  Destiny,
  PayBank,
  TimeUp,
  Bankrupt,
  End
}

export default GameState