import Character from "../types/Character";
import HomeDevice from "../types/HomeDevice";

const format = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format


export default function Bill({character, classes = []}: {character: Character, classes?: string[]}) {
  const getCardWealth = (type: HomeDevice): number => {
    const cards = character.getCards('normal')
    const advanceCards = character.getCards('advance')
    const card = cards.find((c: Card) => c.type === type)
    let wealth = 0
    if (card) {
      wealth = card.price
      if (advanceCards.find((c: Card) => c.type === type)) {
        wealth += card.advancePrice + card.saveCost
      }
    }
    return wealth
  }
  return (<div className={['bill',...classes].join(' ')}>
    <ul className="card-list">
      <li className="card-list-item">{getCardWealth(HomeDevice.AirConditioner)}</li>
      <li className="card-list-item">{getCardWealth(HomeDevice.Refrigerator)}</li>
      <li className="card-list-item">{getCardWealth(HomeDevice.LED)}</li>
      <li className="card-list-item">{getCardWealth(HomeDevice.WaterDispenser)}</li>
      <li className="card-list-item">{getCardWealth(HomeDevice.WaterPot)}</li>
      <li className="card-list-item">{getCardWealth(HomeDevice.TV)}</li>
      <li className="card-list-item">{getCardWealth(HomeDevice.Computer)}</li>
      <li className="card-list-item">{getCardWealth(HomeDevice.WashingMachine)}</li>
      <li className="card-list-item">{getCardWealth(HomeDevice.Fan)}</li>
      <li className="card-list-item">{getCardWealth(HomeDevice.HairDryer)}</li>
      <li className="card-list-item">{getCardWealth(HomeDevice.RicePot)}</li>
      <li className="card-list-item">{getCardWealth(HomeDevice.Dryer)}</li>
      <li className="card-list-item">{getCardWealth(HomeDevice.Microwave)}</li>
      <li className="card-list-item">{getCardWealth(HomeDevice.Heater)}</li>
      <li className="card-list-item">{getCardWealth(HomeDevice.WaterHeater)}</li>
      <li className="card-list-item">{getCardWealth(HomeDevice.Audio)}</li>
    </ul>
    <p className="money">{format(character.getWealth())}</p>
  </div>)
}