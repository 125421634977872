export const Config = {

  CharacterSelectedDelay: 2000,
  GameTimeout: 1200000,
  NoticeTimeout: 3000,
  SaveEnergyTimeout: 4000,
  ConfirmDelay: 2000,
  OpportunityBeforeResultTimeout: 2000,
  OpportunityResultDuringTimeout: 5000,
  DestinyDuringTimeout: 6000,
  BankruptDuringTimeout: 5000,
  ResultTimeout: 15000
}