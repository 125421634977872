import HomeDevice from './HomeDevice'
const CardSet: { type: HomeDevice, name: string, image: string}[] = [
  { 
    type: HomeDevice.AirConditioner,
    name: '冷氣',
    image: 'air-conditioner'
  },
  { 
    type: HomeDevice.Refrigerator,
    name: '冰箱',
    image: 'refrigerator'
  },
  { 
    type: HomeDevice.LED,
    name: 'LED燈具',
    image: 'led'
  },
  { 
    type: HomeDevice.WaterDispenser,
    name: '開飲機',
    image: 'water-dispenser'
  },
  { 
    type: HomeDevice.WaterPot,
    name: '熱水壺',
    image: 'water-pot'
  },
  { 
    type: HomeDevice.TV,
    name: '電視',
    image: 'tv'
  },
  { 
    type: HomeDevice.Computer,
    name: '電腦',
    image: 'computer'
  },
  { 
    type: HomeDevice.WashingMachine,
    name: '洗衣機',
    image: 'washing-machine'
  },
  { 
    type: HomeDevice.Fan,
    name: '電風扇',
    image: 'fan'
  },
  { 
    type: HomeDevice.HairDryer,
    name: '吹風機',
    image: 'hair-dryer'
  },
  { 
    type: HomeDevice.RicePot,
    name: '電鍋',
    image: 'rice-pot'
  },
  { 
    type: HomeDevice.Dryer,
    name: '烘衣機',
    image: 'dryer'
  },
  { 
    type: HomeDevice.Microwave,
    name: '微波爐',
    image: 'microwave'
  },
  { 
    type: HomeDevice.Heater,
    name: '電暖氣',
    image: 'heater'
  },
  { 
    type: HomeDevice.WaterHeater,
    name: '熱水器',
    image: 'water-heater'
  },
  { 
    type: HomeDevice.Audio,
    name: '音響',
    image: 'audio'

  },
]
export default CardSet